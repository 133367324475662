.footerGradient {
  background: rgb(247, 148, 29);
  background: linear-gradient(
    90deg,
    rgba(247, 148, 29, 1) 0%,
    rgba(237, 28, 36, 1) 63%
  );
  width: 100%;
  height: 9vh;
  border-radius: 1rem 1rem 0 0;
  font-size: 0.7rem;
  display: flex;
  justify-content: space-between;
}

.footerText {
  padding: 1.3rem 0 0 15rem;
  color: var(--whiteColor);
  font-weight: 600;
}

.footerText span {
  padding: 0 0 0 0.5rem;
  font-size: 0.8rem;
  font-weight: 800;
  letter-spacing: 0.1rem;
}

.footerCopy {
  padding: 1.3rem 15rem 0 0;
  color: var(--whiteColor);
  font-weight: 600;
}

.footerCopy span {
  padding: 0 0 0 0.5rem;
  font-size: 0.8rem;
  font-weight: 800;
  letter-spacing: 0.1rem;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .footerGradient {
    width: 158%;
    height: 7vh;
  }

  .footerText {
    padding: 1.5rem 0 0 3rem;
    font-size: 0.6rem;
  }

  .footerCopy {
    padding: 1.5rem 3rem 0 0;
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .footerGradient {
    width: 137%;
    height: 18vh;
  }

  .footerText {
    padding: 1.5rem 0 0 5rem;
    font-size: 0.6rem;
  }

  .footerCopy {
    padding: 1.5rem 5rem 0 0;
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) and (orientation: portrait) {
  .footerGradient {
    height: 7vh;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .footerGradient {
    width: 126%;
    height: 15vh;
  }

  .footerText {
    padding: 1.5rem 0 0 8rem;
    font-size: 0.6rem;
  }

  .footerCopy {
    padding: 1.5rem 8rem 0 0;
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) and (orientation: portrait) {
  .footerGradient {
    height: 7vh;
    width: 130%;
  }

  .footerText {
    padding-top: 2rem;
  }

  .footerCopy {
    padding-top: 2rem;
  }
}

@media screen and (min-width: 993px) and (max-width: 1365px) {
  .footerText {
    padding: 2rem 0 0 10rem;
    font-size: 0.8rem;
  }

  .footerText span {
    font-size: 0.9rem;
  }

  .footerCopy {
    padding: 2rem 10rem 0 0;
    font-size: 0.8rem;
  }

  .footerCopy span {
    font-size: 0.9rem;
  }
}
