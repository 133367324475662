.contactContainer {
  margin: 4rem 4rem;
  display: flex;
  height: 100vh;
}

.infoContact {
  width: 50%;
  height: 98vh;
}

.infoContact h1 {
  text-align: start;
  padding: 0 0 1rem 0;
}

.infoContact h2 {
  color: var(--headerColor);
  text-align: start;
  font-size: var(--headerFontSize);
}

.address {
  width: 100%;
  height: 20%;
  position: relative;
  display: grid;
  padding: 1rem 1rem 1rem 0;
}

.address i {
  position: absolute;
  display: flex;
  align-items: flex-start;
  padding: 1.5rem 0 0 2rem;
  font-size: 4rem;
}

.address p {
  text-align: justify;
  padding: 1rem 0 0 10rem;
}

.address h3 {
  padding: 0 8rem 0 0;
}

.address > .tel i {
  padding: 1.1rem 0 0.5rem 2rem;
  font-size: 4rem;
}

.address > .tel h3 {
  padding: 0 6rem 0 0;
}

.contact {
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactForm {
  width: 100%;
  height: 100%;
  padding: 1rem 0 2rem 5rem;
  display: flex;
  flex-direction: column;
}

.contactInput {
  margin: 0.4rem 0 0.5rem 0;
  /* border: 0.02rem solid var(--brownColor); */
  background-color: rgba(247, 148, 29, .8);
  border-radius: 0.5rem;
  cursor: text;
  padding: 0 0 0 2rem;
  border-style: none;
  outline: none;
  caret-color: whitesmoke;
}

input,
button {
  height: 3rem;
}

.contactForm button {
  width: 50%;
  height: 4rem;
  margin: 1.3rem 0 0 9rem;
  background-color: rgba(247, 148, 29, .8);
  color: var(--whiteColor);
  cursor: pointer;
  font-weight: 620;
  padding-right: 2.2rem;
}

.contactForm ::placeholder {
  display: flex;
  text-align: center;
  width: 70%;
  transform: translateX(10%);
  color: var(--whiteColor);
  font-weight: 620;
  letter-spacing: 0.1rem;
}

.contactForm > textarea {
  resize: none;
  padding: 2rem 0 0 4rem;
}

.contactForm > textarea::placeholder {
  padding: 3rem 0 0 0;
}

#address-box {
  padding-left: 4rem;
}

.inputRow1 {
  display: flex;
  justify-content: space-between;
}

.inputRow1 input {
  width: 45%;
}

.labelRow1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 1rem;
  padding: 1rem 0;
}

.labelRow1 span {
  color: var(--redColor);
  padding: 0 0 0 0.5rem;
}

.inputRow2 {
  display: flex;
  justify-content: space-between;
}

.inputRow2 input {
  width: 45%;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .contactContainer {
    margin: 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    height: 150vh;
    width: 154%;
  }

  .infoContact {
    width: 100%;
  }

  .infoContact h1 {
    font-size: 1.6rem;
  }

  .address i {
    font-size: 2.2rem;
  }

  .address h3 {
    padding: 0 10rem 0 0;
  }

  .address > .tel i {
    font-size: 2.2rem;
  }

  .address > .tel h3 {
    padding: 0.8rem 8rem 0 0;
  }

  .address p {
    padding: 1rem 0 0 8.5rem;
  }

  .contact {
    width: 90%;
  }

  .contactForm {
    padding: 0 0 2rem 3rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .contactContainer {
    margin: 2rem 5rem;
    display: flex;
    flex-direction: column;
    height: 270vh;
    width: 120%;
  }

  .infoContact {
    width: 100%;
    height: 25rem;
  }

  .infoContact h2 {
    font-size: 1.1rem;
  }

  .infoContact h1 {
    font-size: 1.4rem;
  }

  .infoContact > .address:nth-child(5) {
    padding-top: 2.5rem;
  }

  .address i {
    font-size: 2.7rem;
    padding: 1.5rem 0 0 6rem;
  }

  .infoContact > .address:nth-child(5) > i {
    padding-top: 3rem;
  }

  .address h3 {
    padding: 0 12rem 0 0;
  }

  .address > .tel i {
    padding: 1.5rem 0 3rem 6rem;
    font-size: 2.7rem;
  }

  .address > .tel h3 {
    padding: 0.8rem 10rem 0 0;
  }

  .address p {
    padding: 1rem 0 0 15rem;
  }

  .contact {
    width: 80%;
    height: 38rem;
    padding-left: 9rem;
  }

  .contactForm {
    padding: 0 0 2rem 3rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .contactContainer {
    margin: 2rem 3rem;
    display: flex;
    flex-direction: row;
    height: 150vh;
    width: 110%;
  }

  .infoContact {
    width: 55%;
    height: 25rem;
  }

  .infoContact h2 {
    font-size: 1.1rem;
  }

  .infoContact h1 {
    font-size: 1.4rem;
  }

  .infoContact > .address:nth-child(5) {
    padding-top: 2.5rem;
  }

  .address i {
    font-size: 2rem;
    padding: 1.5rem 0 0 2rem;
  }

  .infoContact > .address:nth-child(5) > i {
    padding-top: 3rem;
  }

  .address h3 {
    padding: 0 8rem 0 0;
  }

  .address > .tel i {
    padding: 2.3rem 0 3rem 2rem;
    font-size: 2rem;
  }

  .address > .tel h3 {
    padding: 1.5rem 6rem 0 0;
  }

  .address p {
    padding: 1rem 0 0 6rem;
  }

  .contact {
    width: 55%;
    height: 38rem;
    padding-right: 1rem;
  }

  .contactForm {
    padding: 0 0 2rem 3rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) and (orientation: portrait) {
  .contactContainer {
    margin: 2rem 3rem;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
  }

  .infoContact {
    width: 100%;
  }

  .address i {
    font-size: 3rem;
    padding: 1.5rem 0 0 5rem;
  }

  .address h3 {
    padding: 0 8rem 0 0;
  }

  .address > .tel i {
    padding: 2rem 0 3rem 5rem;
    font-size: 3rem;
  }

  .address > .tel h3 {
    padding: 1.5rem 6rem 0 0;
  }

  .address p {
    padding: 1rem 0 0 16rem;
  }

  .contact {
    width: 90%;
    height: 38rem;
    padding-left: 8rem;
  }
}
