.aboutbody {
  margin: 4rem 4rem;
  width: 90%;
}

.aboutus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5rem;
}

.aboutText {
  width: 70%;
  text-align: justify;
  font-size: 1.1rem;
  line-height: 2rem;
  letter-spacing: 0.05rem;
}

.aboutText h1 {
  text-align: start;
  margin: 0 0 3rem 0;
  color: var(--headerColor);
  font-size: var(--headerFontSize);
}

.imageAboutus {
  width: 30%;
  margin: 0 5rem 0 0;
  border-radius: 10px;
  box-shadow: 0 0 0.9rem 0.5rem #68dafd;
}

@media screen and (max-width: 600px) {
  .aboutbody {
    width: 100%;
  }

  .aboutus {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    justify-content: inherit;
    margin: 0;
    width: 140%;
  }

  .imageAboutus {
    width: 90%;
    height: 40vh;
    margin: 0;
  }

  .aboutText {
    width: 90%;
  }

  .aboutText h1 {
    padding-top: 4rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .aboutbody {
    width: 100%;
  }

  .aboutus {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    justify-content: inherit;
    margin: 0;
    width: 128%;
  }

  .imageAboutus {
    width: 93%;
    height: 115vh;
    margin: 0;
  }

  .aboutText {
    width: 93%;
  }

  .aboutText h1 {
    padding-top: 4rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .aboutbody {
    width: 100%;
  }

  .aboutus {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    justify-content: inherit;
    margin: 0;
    width: 110%;
  }

  .imageAboutus {
    width: 85%;
    height: 115vh;
    margin-left: 8%;
    
  }

  .aboutText {
    width: 100%;
  }

  .aboutText h1 {
    padding-top: 4rem;
  }
}

/* @media screen and (max-width: 850px) {
  .imageAboutus {
    padding: 6rem 0 0 0;
    height: 75vh;
    width: 15rem;
  }
} */
