@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;600;900&display=swap");
.hero {
  width: 100%;
  height: 90vh;
  position: relative;
}

img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.hero-text {
  position: absolute;
  top: 56%;
  left: 10%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.hero-text-back {
  position: absolute;
  top: 56%;
  left: 15%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.hero-text p {
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  background: var(--whiteColor);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-text-back p {
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  background: var(--whiteColor);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 600px) {
  .hero {
    width: 158%;
    height: 50vh;
  }

  .hero-text {
    left: 20%;
  }

  .hero-text-back {
    left: 20%;
  }

  .hero-text p {
    font-size: 1rem;
  }

  .hero-text-back p {
    font-size: 1rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .hero {
    width: 138%;
    height: 100vh;
  }

  .hero-text {
    top: 65%;
    left: 18%;
  }

  .hero-text-back {
    top: 65%;
    left: 20%;
  }

  .hero-text p {
    font-size: 1.5rem;
  }

  .hero-text-back p {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) and (orientation: portrait) {
  .hero {
    width: 138%;
    height: 50vh;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .hero {
    width: 126%;
    height: 85vh;
  }

  .hero-text {
    left: 18%;
  }

  .hero-text-back {
    top: 60%;
    left: 20%;
  }

  .hero-text p {
    font-size: 1.5rem;
  }

  .hero-text-back p {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) and (orientation: portrait) {
  .hero {
    width: 130%;
    height: 40vh;
  }

  .hero-text {
    left: 18%;
  }

  .hero-text-back {
    top: 60%;
    left: 20%;
  }

  .hero-text p {
    font-size: 1.5rem;
  }

  .hero-text-back p {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 993px) and (max-width: 1365px) {
  .hero {
    width: 100%;
    height: 70vh;
  }

  .hero-text {
    left: 18%;
  }

  .hero-text-back {
    top: 60%;
    left: 20%;
  }

  .hero-text p {
    font-size: 1.5rem;
  }

  .hero-text-back p {
    font-size: 1.5rem;
  }
}

/* @media screen and (max-width: 555px) {
  .hero-text p {
    font-size: 1rem;
  }
} */
