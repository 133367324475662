.ourbackground {
  margin: 4rem 4rem;
  padding: 0 10rem;
}

.ourbackgroundText h1 {
  display: flex;
  color: var(--headerColor);
  text-align: flex-start;
  font-size: var(--headerFontSize);
}

.ourbackground h3 {
  display: flex;
  padding: 1rem 0 2rem 0;
  color: var(--headerColor);
  letter-spacing: 0.1rem;
  text-align: flex-start;
}

.ourbackground h3:nth-child(4) {
  padding: 3rem 0 2rem 0;
}

.ourbackgroundText p {
  padding: 0.5rem 0;
  text-align: justify;
  line-height: 2rem;
  letter-spacing: 0.05rem;
}

.imageUnited {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.3rem 0 0 10rem;
  width: 100%;
  height: 50%;
}

.line1,
.line2 {
  position: relative;
  display: flex;
  flex-direction: row;
}

.line2 {
  padding: 3rem 0 0 0;
}

.imageUnited img {
  width: 45%;
  height: 12rem;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0 0 0.5rem 0.2rem var(--brownColor);
}

.imageUnited img:nth-child(2) {
  margin: 0 2rem 0 3rem;
}

.imageUnited img:nth-child(3) {
  margin: 0 2rem 0 0;
}

.imageOur {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 50%;
  padding: 1.3rem 0 2rem 10rem;
}

.imageOur img {
  width: 45%;
  height: 12rem;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0 0 0.5rem 0.2rem var(--brownColor);
}

.imageOur img:nth-child(2) {
  margin: 0 2rem 0 3rem;
}

.imageOur img:nth-child(3) {
  margin: 0 2rem 0 0;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .ourbackground {
    margin: 1rem 1rem;
    padding: 2rem 2rem;
    width: 150%;
  }

  .imageUnited {
    padding: 1.3rem 3rem 2rem 3rem;
  }

  .imageOur {
    padding: 1.3rem 3rem 2rem 3rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .ourbackground {
    margin: 1rem 1rem;
    padding: 2rem 3rem;
    width: 133%;
  }

  .imageUnited {
    padding: 1.3rem 3rem 2rem 3rem;
  }

  .imageOur {
    padding: 1.3rem 3rem 2rem 3rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .ourbackground {
    margin: 1rem 1rem;
    padding: 2rem 3rem;
    width: 123%;
  }

  .imageUnited {
    padding: 1.3rem 3rem 2rem 3rem;
    margin-left: 1rem;
  }

  .imageOur {
    padding: 1.3rem 3rem 2rem 3rem;
    margin-left: 1rem;
  }
}
