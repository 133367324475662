.ourservicesContainer {
  margin: 4rem 4rem;
  padding: 0 5rem;
}

.ourservices {
  align-items: flex-start;
  text-align: justify;
}

.ourservices h1 {
  padding: 2rem 0;
  color: var(--headerColor);
  letter-spacing: 0.1rem;
  font-size: var(--headerFontSize);
}

.ourservices p {
  letter-spacing: 0.09rem;
  text-align: justify;
}

/* .serviceList {
  display: block;
  font-size: 1.1rem;
  padding: 2rem 0 2rem 5rem;
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
  list-style: none;
} */

.listBox1,
.listBox2,
.listBox3 {
  padding: 2rem 0;
  margin: 3rem 0;
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: row;
}

.list1,
.list2,
.list3,
.list4,
.list5,
.list6,
.list7,
.list8,
.list9 {
  position: relative;
  width: 20vw;
  height: 35vh;
  margin: 0 3rem;
  box-shadow: 0 0 0.5rem 0.05rem var(--brownColor);
}

.list1 p {
  padding: 1rem 1rem;
  letter-spacing: 0.08rem;
  text-align: start;
}

.list2 p,
.list3 p,
.list6 p,
.list7 p {
  padding: 1rem 2rem;
  letter-spacing: 0.05rem;
  text-align: start;
}

.list4 p {
  padding: 1rem 1.6rem;
  letter-spacing: 0.05rem;
  text-align: start;
}

.list5 p {
  padding: 1rem 1rem;
  letter-spacing: 0.05rem;
  text-align: start;
  font-size: 0.85rem;
}

.list8 p {
  padding: 1rem 1.5rem;
  letter-spacing: 0.04rem;
  text-align: start;
  font-size: 0.9rem;
}

.list9 p {
  padding: 1rem 1rem;
  letter-spacing: 0.06rem;
  text-align: start;
  font-size: 0.9rem;
}

.list1 img,
.list9 img {
  width: 5rem;
  height: 5rem;
  margin: 2rem 10rem;
}

.list2 img {
  width: 6rem;
  height: 6rem;
  margin: 1.7rem 10rem;
}

.list3 img {
  width: 5rem;
  height: 5.5rem;
  margin: 3.8rem 10.5rem;
}

.list4 img {
  width: 4rem;
  height: 4.5rem;
  margin: 1.5rem 11rem;
}

.list5 img {
  width: 4.5rem;
  height: 5rem;
  margin: 0 10.7rem;
}

.list6 img,
.list7 img {
  width: 4.5rem;
  height: 4.5rem;
  margin: 3.5rem 10.7rem;
}

.list8 img {
  width: 4.3rem;
  height: 4.3rem;
  margin: 1.3rem 11.3rem;
}

.whychooseus {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: justify;
}

.whychooseus h1 {
  color: var(--headerColor);
  padding: 1rem 0 2rem 0;
  letter-spacing: 0.1rem;
  font-size: var(--headerFontSize);
}

.whychooseus p {
  line-height: 1.5rem;
  letter-spacing: 0.09rem;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .ourservicesContainer {
    margin: 1rem 1rem;
    padding: 0 2rem;
    width: 150%;
  }

  .list1,
  .list2,
  .list3,
  .list4,
  .list5,
  .list6,
  .list7,
  .list8,
  .list9 {
    width: 40vw;
    margin: 0 0.5rem;
  }

  .list7 p {
    padding: 1rem 1rem;
  }

  .list4 p {
    padding: 1rem 1rem;
  }

  .list1 img {
    margin: 5rem 4rem;
  }

  .list2 img {
    margin: 6rem 4rem;
  }

  .list3 img {
    margin: 8rem 4rem;
  }

  .list4 img {
    margin: 4rem 5rem;
  }

  .list5 img {
    margin: 0 4rem;
  }

  .list6 img {
    margin: 5.5rem 4rem;
  }

  .list7 img {
    margin: 9rem 4rem;
  }

  .list8 img {
    margin: 1.5rem 5rem;
  }

  .list9 img {
    margin: 3.5rem 3.5rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .ourservicesContainer {
    margin: 1rem 1rem;
    padding: 0 2rem;
    width: 130%;
  }

  .listBox1,
  .listBox2,
  .listBox3 {
    height: 50vh;
  }

  .list1,
  .list2,
  .list3,
  .list4,
  .list5,
  .list6,
  .list7,
  .list8,
  .list9 {
    width: 40vw;
    height: 50vh;
    margin: 0 0.5rem;
  }

  .list7 p {
    padding: 1rem 1rem;
  }

  .list4 p {
    padding: 1rem 1rem;
  }

  .list1 img {
    width: 3rem;
    height: 3rem;
    margin: 2rem 5rem;
    transform: translateX(6rem);
  }

  .list2 img {
    width: 4rem;
    height: 4rem;
    margin: 1.5rem 5rem;
    transform: translateX(5rem);
  }

  .list3 img {
    width: 4rem;
    height: 4rem;
    margin: 3rem 5rem;
    transform: translateX(5rem);
  }

  .list4 img {
    width: 3rem;
    height: 3rem;
    margin: 0.8rem 5rem;
    transform: translateX(6.2rem);
  }

  .list5 img {
    width: 3rem;
    height: 3rem;
    margin: 0 5rem;
    transform: translateX(5.8rem) translateY(-0.9rem);
  }

  .list6 img {
    width: 3rem;
    height: 3rem;
    margin: 2.3rem 5rem;
    transform: translateX(6rem);
  }

  .list7 img {
    width: 3rem;
    height: 3rem;
    margin: 3.8rem 5rem;
    transform: translateX(6rem);
  }

  .list8 img {
    width: 3rem;
    height: 3rem;
    margin: 0 5rem;
    transform: translateX(6rem) translateY(-0.4rem);
  }

  .list9 img {
    width: 3rem;
    height: 3rem;
    margin: 1.2rem 5rem;
    transform: translateX(6rem);
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) and (orientation: portrait) {
  .listBox1,
  .listBox2,
  .listBox3 {
    height: 20vh;
  }

  .list1,
  .list2,
  .list3,
  .list4,
  .list5,
  .list6,
  .list7,
  .list8,
  .list9 {
    width: 40vw;
    height: 20vh;
    margin: 0 0.5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .ourservicesContainer {
    margin: 1rem 1rem;
    padding: 0 2.5rem;
    width: 124%;
  }

  .listBox1,
  .listBox2,
  .listBox3 {
    height: 50vh;
  }

  .list1,
  .list2,
  .list3,
  .list4,
  .list5,
  .list6,
  .list7,
  .list8,
  .list9 {
    width: 40vw;
    height: 50vh;
    margin: 0 0.5rem;
  }

  .list7 p {
    padding: 1rem 1rem;
  }

  .list4 p {
    padding: 1rem 1rem;
  }

  .list1 img {
    width: 3rem;
    height: 3rem;
    margin: 3rem 5rem;
    transform: translateX(8rem);
  }

  .list2 img {
    width: 4rem;
    height: 4rem;
    margin: 4rem 5rem;
    transform: translateX(7rem);
  }

  .list3 img {
    width: 4rem;
    height: 4rem;
    margin: 4rem 5rem;
    transform: translateX(7rem);
  }

  .list4 img {
    width: 3rem;
    height: 3rem;
    margin: 3rem 5rem;
    transform: translateX(8.5rem);
  }

  .list5 img {
    width: 3rem;
    height: 3rem;
    margin: 1.3rem 5rem;
    transform: translateX(7.5rem);
  }

  .list6 img {
    width: 3rem;
    height: 3rem;
    margin: 3.4rem 5rem;
    transform: translateX(8rem);
  }

  .list7 img {
    width: 2.8rem;
    height: 2.8rem;
    margin: 4.8rem 5rem;
    transform: translateX(9rem);
  }

  .list8 img {
    width: 3rem;
    height: 3rem;
    margin: 2rem 5rem;
    transform: translateX(8rem);
  }

  .list9 img {
    width: 3rem;
    height: 3rem;
    margin: 2rem 5rem;
    transform: translateX(8.5rem);
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) and (orientation: portrait) {
  .listBox1,
  .listBox2,
  .listBox3 {
    height: 20vh;
  }

  .list1,
  .list2,
  .list3,
  .list4,
  .list5,
  .list6,
  .list7,
  .list8,
  .list9 {
    width: 40vw;
    height: 20vh;
    margin: 0 0.5rem;
  }
}

@media screen and (min-width: 993px) and (max-width: 1365px) {
  .ourservicesContainer {
    margin: 1rem 1rem;
    padding: 0 3rem;
    width: 99%;
  }

  .listBox1,
  .listBox2,
  .listBox3 {
    height: 30vh;
  }

  .list1,
  .list2,
  .list3,
  .list4,
  .list5,
  .list6,
  .list7,
  .list8,
  .list9 {
    width: 40vw;
    height: 30vh;
    margin: 0 0.5rem;
  }
  .list1 img {
    width: 3rem;
    height: 3rem;
    margin: 4rem 5rem;
    transform: translateX(7rem);
  }

  .list2 img {
    width: 4rem;
    height: 4rem;
    margin: 3.5rem 5rem;
    transform: translateX(6rem);
  }

  .list3 img {
    width: 4rem;
    height: 4rem;
    margin: 5rem 5rem;
    transform: translateX(6rem);
  }

  .list4 img {
    width: 3rem;
    height: 3rem;
    margin: 3rem 5rem;
    transform: translateX(7rem);
  }

  .list5 img {
    width: 3rem;
    height: 3rem;
    margin: 1.3rem 5rem;
    transform: translateX(7rem);
  }

  .list6 img {
    width: 3rem;
    height: 3rem;
    margin: 4.5rem 5rem;
    transform: translateX(6.7rem);
  }

  .list7 img {
    width: 2.8rem;
    height: 2.8rem;
    margin: 4.8rem 5rem;
    transform: translateX(7rem);
  }

  .list8 img {
    width: 3rem;
    height: 3rem;
    margin: 2.5rem 5rem;
    transform: translateX(7rem);
  }

  .list9 img {
    width: 3rem;
    height: 3rem;
    margin: 3.8rem 5rem;
    transform: translateX(7rem);
  }
}
