@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;600;900&display=swap");
.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image {
  width: 78.2rem;
  height: 40rem;
  object-fit: fill;
}

.text {
  position: absolute;
  padding: 30rem 0 0 5rem;
  font-size: var(--sliderFontSize);
  font-style: italic;
  width: 100%;
  color: var(--secondary);
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 7%;
  font-size: 3rem;
  color: var(--whiteColor);
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 7%;
  font-size: 3rem;
  color: var(--whiteColor);
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 0.8s;
}

.slide.active {
  opacity: 1;
  transition: 1s linear;
  transform: scale(1.08);
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .image {
    width: 150%;
    height: 100%;
    object-fit: fill;
  }

  .text {
    position: absolute;
    padding: 21rem 0 0 5rem;
    font-size: 80%;
    font-style: italic;
    width: 150%;
    color: var(--secondary);
  }

  .left-arrow {
    top: 50%;
    left: 2rem;
    font-size: 2rem;
    z-index: 15;
  }

  .right-arrow {
    top: 50%;
    right: -13rem;
    font-size: 2rem;
    z-index: 15;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .image {
    width: 131%;
    height: 100%;
    object-fit: cover;
  }

  .text {
    position: absolute;
    padding: 69% 0 0 5rem;
    font-size: 80%;
    font-style: italic;
    width: 131%;
    color: var(--secondary);
  }

  .left-arrow {
    top: 50%;
    left: 2rem;
    font-size: 2rem;
    z-index: 15;
  }

  .right-arrow {
    top: 50%;
    right: -13.8rem;
    font-size: 2rem;
    z-index: 15;
  }
}

/* @media screen and (min-width: 601px) and (max-width: 768px) and (orientation: landscape) {
  .image {
    width: 110%;
    height: 100%;
    object-fit: fill;
  }

  .text {
    position: absolute;
    padding: 27rem 0 0 5rem;
    font-size: 95%;
    font-style: italic;
    width: 100%;
    color: var(--secondary);
  }

  .left-arrow {
    top: 50%;
    left: 2rem;
    font-size: 2rem;
    z-index: 15;
  }

  .right-arrow {
    top: 50%;
    right: -3.8rem;
    font-size: 2rem;
    z-index: 15;
  }
} */

@media screen and (min-width: 769px) and (max-width: 992px) {
  .image {
    width: 120%;
    height: 100%;
    object-fit: fill;
  }

  .text {
    position: absolute;
    padding: 58% 0 0 5rem;
    font-size: 100%;
    font-style: italic;
    width: 110%;
    color: var(--secondary);
  }

  .left-arrow {
    top: 50%;
    left: 4rem;
    font-size: 2rem;
    z-index: 15;
  }

  .right-arrow {
    top: 50%;
    right: -9rem;
    font-size: 2rem;
    z-index: 15;
  }
}

/* @media screen and (min-width: 769px) and (max-width: 992px) and (orientation: landscape) {
  .image {
    width: 110%;
    height: 100%;
    object-fit: fill;
  }

  .text {
    position: absolute;
    padding: 36rem 0 0 5rem;
    font-size: 100%;
    font-style: italic;
    width: 100%;
    color: var(--secondary);
  }

  .left-arrow {
    top: 50%;
    left: 3rem;
    font-size: 2rem;
    z-index: 15;
  }

  .right-arrow {
    top: 50%;
    right: -5.8rem;
    font-size: 2rem;
    z-index: 15;
  }
} */

@media screen and (min-width: 1920px) {
  .slider {
    display: flex;
    justify-content: flex-start;
    width: 75%;
  }

  .image {
    width: 150.65%;
    height: 50rem;
    object-fit: fill;
  }

  .text {
    position: absolute;
    padding: 55% 0 0 0;
    font-size: 150%;
    font-style: italic;
    width: 150%;
    color: var(--secondary);
  }

  .left-arrow {
    top: 50%;
    left: 6rem;
    font-size: 3rem;
    z-index: 10;
  }

  .right-arrow {
    top: 50%;
    right: -25rem;
    font-size: 3rem;
    z-index: 10;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1910px) and (orientation: portrait) {
  .slider {
    display: flex;
    justify-content: flex-start;
  }

  .image {
    width: 140%;
    height: 100%;
    object-fit: fill;
  }

  .text {
    position: absolute;
    padding: 95% 0 0 10%;
    font-size: 200%;
    font-style: italic;
    width: 180%;
    color: var(--secondary);
  }

  .left-arrow {
    top: 50%;
    left: 6rem;
    font-size: 5rem;
    z-index: 15;
  }

  .right-arrow {
    top: 50%;
    right: -55rem;
    font-size: 5rem;
    z-index: 15;
  }
}

/* @media screen and (min-width: 1921px) and (max-width: 2400px) and (orientation: portrait) {
  .slider {
    display: flex;
    justify-content: flex-start;
  }

  .image {
    width: 150%;
    height: 100%;
    object-fit: fill;
  }

  .text {
    position: absolute;
    padding: 80% 0 0 5rem;
    font-size: 150%;
    font-style: italic;
    width: 150%;
    color: var(--secondary);
  }

  .left-arrow {
    top: 50%;
    left: 3rem;
    font-size: 3.5rem;
    z-index: 15;
  }

  .right-arrow {
    top: 50%;
    right: -3rem;
    font-size: 3.5rem;
    z-index: 15;
  }
} */

/* @media screen and (min-width: 1921px) and (max-width: 2400px) and (orientation: landscape) {
  .slider {
    display: flex;
    justify-content: flex-start;
  }

  .image {
    width: 190%;
    height: 100%;
    object-fit: contain;
  }

  .text {
    position: absolute;
    padding: 100% 0 0 10%;
    font-size: 200%;
    font-style: italic;
    width: 190%;
    color: var(--secondary);
  }

  .left-arrow {
    top: 50%;
    left: 3rem;
    font-size: 4rem;
    z-index: 15;
  }

  .right-arrow {
    top: 50%;
    right: -5.8rem;
    font-size: 4rem;
    z-index: 15;
  }
} */
