.homebody {
  margin: 4rem 4rem;
  padding: 0 10rem;
}

.value h1 {
  font-size: var(--headerFontSize);
  color: var(--headerColor);
  text-align: start;
}

.valueBox1,
.valueBox2 {
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: row;
}

.valueBox1 > :nth-child(1) {
  background-color: var(--secondary);
}

.box1 {
  position: relative;
  width: 25vw;
  height: 35vh;
  padding: 6.5rem 5rem 0 5rem;
  margin: 0 0 0 5rem;
  background-color: var(--brownColor);
}

.box1 h3 {
  color: var(--whiteColor);
  letter-spacing: 0.1rem;
  font-size: 1.5rem;
  font-style: italic;
}

/* .boxBottom {
  margin: 6.8rem 0 0 -5rem;
  width: 25vw;
  height: 2vh;
  background-color: var(--secondary);
  background: rgb(247, 148, 29);
  background: linear-gradient(
    90deg,
    rgba(247, 148, 29, 1) 0%,
    rgba(237, 28, 36, 1) 63%
  );
} */

.objective h1 {
  font-size: var(--headerFontSize);
  color: var(--headerColor);
  text-align: start;
  padding: 1.4rem 0 0 0;
}

.value p {
  font-size: var(--pageFontSize);
  margin: 1rem 0 4rem 0;
  text-align: justify;
  line-height: 2rem;
  letter-spacing: 0.05rem;
}

.objective p {
  font-size: var(--pageFontSize);
  text-align: justify;
  line-height: 2rem;
  letter-spacing: 0.05rem;
  padding: 1rem 0 0 0;
}

/* .objective p:nth-child(2) {
  position: relative;
  padding: 1.2rem 2.7rem 0 0;
}

.objective p:nth-child(4) {
  position: relative;
  padding: 0 2rem 0 0;
}

.objective p:nth-child(6) {
  position: relative;
  padding: 0 12.5rem 0 0;
} */

@media screen and (max-width: 600px) and (orientation: portrait) {
  .homebody {
    width: 150%;
    margin: 1rem 1rem;
    padding: 0 2rem;
  }

  .valueBox1,
  .valueBox2 {
    height: 28vh;
  }

  .box1 {
    height: 20vh;
    padding: 4.3rem 5rem 0 7rem;
    margin: 0 0 0 4rem;
  }

  .box1 h3 {
    letter-spacing: 0.1rem;
    font-size: 1.2rem;
    font-style: italic;
    transform: translateX(-4.5rem);
  }

  .valueBox1 > .box1:nth-child(2) > h3 {
    transform: translateX(-4rem);
  }

  .valueBox2 > .box1:nth-child(2) > h3 {
    transform: translateX(-6.3rem);
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .homebody {
    width: 130%;
    margin: 2rem 1rem;
    padding: 0 2rem;
  }

  .valueBox1,
  .valueBox2 {
    height: 25vh;
    margin-top: 2rem;
  }

  .box1 {
    height: 20vh;
    padding: 9rem 9rem 0 9rem;
  }

  .box1 h3 {
    transform: translateX(-4rem) translateY(-3.5rem);
  }

  .valueBox1 > .box1:nth-child(2) > h3 {
    transform: translateX(-3rem) translateY(-3.5rem);
  }

  .valueBox2 > .box1:nth-child(2) > h3 {
    transform: translateX(-6rem) translateY(-3.5rem);
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) and (orientation: landscape) {
  .homebody {
    width: 110%;
    margin: 2rem 1rem;
    padding: 0 2rem;
  }

  .valueBox1,
  .valueBox2 {
    margin: 4rem 0 0 8rem;
  }

  .box1 {
    height: 40vh;
    padding: 7rem 8rem 0 8rem;
  }

  .box1 h3 {
    transform: translateX(-4rem) translateY(-3rem);
  }

  .valueBox1 > .box1:nth-child(2) > h3 {
    transform: translateX(-3.5rem) translateY(-3rem);
  }

  .valueBox2 > .box1:nth-child(2) > h3 {
    transform: translateX(-6rem) translateY(-3rem);
  }

  .value p {
    width: 110%;
  }

  .objective p {
    width: 110%;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) and (orientation: portrait) {
  .homebody {
    width: 110%;
    margin: 2rem 1rem;
    padding: 2rem 2rem;
  }

  .valueBox1,
  .valueBox2 {
    height: 25vh;
    margin: 2rem 0 0 7rem;
  }

  .box1 {
    height: 20vh;
    padding: 7rem 8rem 0 8rem;
  }

  .box1 h3 {
    transform: translateX(-4rem) translateY(-1rem);
  }

  .valueBox1 > .box1:nth-child(2) > h3 {
    transform: translateX(-3.5rem) translateY(-1rem);
  }

  .valueBox2 > .box1:nth-child(2) > h3 {
    transform: translateX(-6rem) translateY(-1rem);
  }

  .value p {
    width: 110%;
  }

  .objective p {
    width: 110%;
  }
}

@media screen and (min-width: 993px) and (max-width: 1365px) and (orientation: landscape) {
  .homebody {
    margin: 2rem 1rem;
    padding: 2rem 4rem;
  }

  .valueBox1,
  .valueBox2 {
    margin: 2rem 0 0 10rem;
    width: 90%;
    height: 35vh;
  }

  .box1 {
    height: 30vh;
    padding: 8rem 6rem 0 5rem;
  }

  .valueBox2 > .box1:nth-child(2) {
    padding-left: 5.5rem;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1910px) and (orientation: landscape) {
  .homebody {
    margin: 2rem 1rem;
    padding: 2rem 4rem;
  }

  .valueBox1,
  .valueBox2 {
    margin: 2rem 0 0 10rem;
    width: 90%;
    height: 35vh;
  }

  .box1 {
    height: 30vh;
    padding: 8rem 6rem 0 5rem;
  }

  .valueBox2 > .box1:nth-child(2) {
    padding-left: 5.5rem;
  }
}

@media screen and (min-width: 1920px) {
  .homebody {
    margin: 2rem 1rem;
    padding: 2rem 4rem;
  }

  .valueBox1,
  .valueBox2 {
    margin: 2rem 0 0 15rem;
    width: 90%;
    height: 35vh;
  }

  .box1 {
    height: 30vh;
    padding: 8rem 6rem 0 5rem;
  }

  .valueBox2 > .box1:nth-child(2) {
    padding-left: 5.5rem;
  }
}
