.navbar {
  background-color: var(--whiteColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  box-shadow: 0 5px 15px 0px #f7951d57;
  width: 95%;
  height: 80px;
  border-radius: 13px;
  top: 1rem;
  left: 50%;
  transform: translate(-50%);
  position: fixed;
  z-index: 9999;
}

.navbar-logo {
  cursor: pointer;
  width: 10rem;
  height: 5rem;
  padding-top: 0.5rem;
}

.navbar-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 2rem;
  list-style: none;
  align-items: center;
}

.navbar-links {
  text-decoration: none;
  color: var(--brownColor);
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.1rem 0.2rem;
  white-space: nowrap;
}

.navbar-links i {
  padding-right: 0.8rem;
  font-size: 1rem;
}

.navbar-links:hover {
  background-color: var(--primary);
  color: beige;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  padding: 2rem 3rem;
  font-size: 1.1rem;
}

.menu-icons {
  color: var(--brownColor);
  cursor: pointer;
  font-size: 1.2rem;
  display: none;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .navbar {
    z-index: 99;
    height: 60px;
    padding: 0 3rem;
  }

  .navbar-logo {
    width: 6rem;
    height: 3rem;
  }
  .navbar-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: var(--whiteColor);
    width: 95%;
    height: auto;
    position: absolute;
    top: 0;
    left: -100%;
    opacity: 0;
    align-items: center;
    padding: 7rem 0 2rem 0;
    margin: 0;
    transition: 0.3s ease-in-out;
    border-radius: 13px;
  }

  .navbar-menu.active {
    left: 0;
    opacity: 1;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }

  .navbar-links {
    display: block;
    width: 100%;
    height: 7vh;
    font-size: 0.9rem;
    padding: 1.5rem 0;
  }

  .navbar-links:hover {
    background-color: var(--primary);
    transition: 0.3s ease-in-out;
  }

  .menu-icons {
    display: block;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .navbar {
    z-index: 99;
  }
  .navbar-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: var(--whiteColor);
    width: 95%;
    height: auto;
    position: absolute;
    top: 0;
    left: -100%;
    opacity: 0;
    align-items: center;
    padding: 7rem 0 2rem 0;
    margin: 0;
    transition: 0.3s ease-in-out;
    border-radius: 13px;
  }

  .navbar-menu.active {
    left: 0;
    opacity: 1;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }

  .navbar-links {
    display: block;
    width: 100%;
    height: 9vh;
    font-size: 0.9rem;
    padding: 1rem 0;
  }

  .navbar-links:hover {
    background-color: var(--primary);
    transition: 0.3s ease-in-out;
  }

  .menu-icons {
    display: block;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) and (orientation: portrait) {
  .navbar {
    z-index: 99;
  }
  .navbar-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: var(--whiteColor);
    width: 95%;
    height: auto;
    position: absolute;
    top: 0;
    left: -100%;
    opacity: 0;
    align-items: center;
    padding: 7rem 0 2rem 0;
    margin: 0;
    transition: 0.3s ease-in-out;
    border-radius: 13px;
  }

  .navbar-menu.active {
    left: 0;
    opacity: 1;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }

  .navbar-links {
    display: block;
    width: 100%;
    font-size: 0.9rem;
    padding: 1.5rem 0;
  }

  .navbar-links:hover {
    background-color: var(--primary);
    transition: 0.3s ease-in-out;
  }

  .menu-icons {
    display: block;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) and (orientation: landscape) {
  .navbar {
    height: 50px;
    z-index: 99;
    padding: 0 2rem;
  }

  .navbar-logo {
    width: 7rem;
    height: 3.5rem;
  }

  .navbar-menu {
    display: grid;
    grid-gap: 1.5rem;
  }

  .navbar-links {
    font-size: 0.7rem;
    padding: 1.5rem .2rem;
  }

  .navbar-links i {
    padding: 0 .4rem;
    font-size: .8rem;
  }
}



/* <600px, 601px, 768px, 992px, 1200px  */
